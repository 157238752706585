<template>
  <div class="body">
    <div class="content">
      <div v-html="detailArticleData.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "articleDetail",
  data() {
    return {
      detailArticleData: "",
    };
  },
  mounted() {
    this.detailArticleData = JSON.parse(
      window.sessionStorage.getItem("currentArticle")
    );
  },
};
</script>

<style>
.content img{
  width: 99%;
}
.content{
  font-size: 0.4rem;
  width: 97%;
  margin: auto;
}
</style>